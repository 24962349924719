import React, { useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import { format } from 'date-fns';
import {applyJobsUpwork} from "../../services/lookup";
import logger from '../../utils/logger';

const JobTableUpwork = ({ jobs }) => {
  const [showModal, setShowModal] = useState(false);
  const [asWho, setAsWho] = useState('Freelancer');
  const [attachment, setAttachment] = useState('Node.js');
  const [coverLetter, setCoverLetter] = useState('Me as Node.js');
  const [jobUrl, setJobUrl] = useState('');

  const showFullDescription = (description) => {
    alert(description); // Replace with your preferred way to display full description
  };

  const handleApplyClick = (applyUrl) => {
    setJobUrl(applyUrl);
    setShowModal(true);
  };
    
  const handleApply = async () => {
    try {
        const attachmentUrl = 'https://tinyurl.com/altarey-upwork-promo';
        const coverLetterText = `My cover letter (${coverLetter})`;

        const result = await applyJobsUpwork({
            useAgency: asWho === 'Agency' ? 1 : 0,
            useFreelancer: asWho === 'Freelancer' ? 1 : 0,
            attachmentUrl,
            coverLetter: coverLetterText,
            jobUrl
        });

        setShowModal(false); // Close the modal after successfully applying
    } catch (error) {
        logger.error('Error applying for job:', error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }) => (
          <span
            className="custom-tooltip"
            onClick={() => showFullDescription(value)} // Show full description on click
          >
            {value.split(' ').slice(0, 4).join(' ')}...
          </span>
        ),
      },
      {
        Header: 'Job Type',
        accessor: 'jobType',
      },
      {
        Header: 'Contractor Tier',
        accessor: 'contractorTier',
      },
      {
        Header: 'Budget',
        accessor: 'budget',
      },
      {
          Header: 'Skills',
          accessor: 'skills',
          // Update the Cell renderer for the "Skills" column
          Cell: ({ value }) => (
            <ul style={{ listStyleType: "circle", paddingLeft: "20px", margin: 0 }}>
              {value.map(skill => (
                <li key={skill}>{skill}</li>
              ))}
            </ul>
          ),
      },
      {
        Header: 'Duration',
        accessor: 'duration',
      },
      {
        Header: 'Engagement',
        accessor: 'engagement',
      },
      {
        Header: 'Proposals',
        accessor: 'proposals',
      },
      {
        Header: 'Connect Price',
        accessor: 'connectPrice',
      },
      {
        Header: 'Client Country',
        accessor: 'clientCountry',
      },
      {
        Header: 'Client Rating',
        accessor: 'clientRating',
      },
      {
        Header: 'Client Spent',
        accessor: 'clientSpent',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: ({ value }) => format(new Date(value), 'MM/dd/yyyy HH:mm:ss'),
      },
      {
        Header: 'Scraped At',
        accessor: 'scrapedAt',
        Cell: ({ value }) => format(new Date(value), 'MM/dd/yyyy HH:mm:ss'),
      },
      {
        Header: 'URL',
        accessor: 'url',
        Cell: ({ value }) => <button onClick={() => window.open(value, '_blank')} className="apply-button">View</button>,
      },
      {
        Header: 'Apply URL',
        accessor: 'applyUrl',
        Cell: ({ value }) => <button onClick={() => window.open(value, '_blank')} className="apply-button">Apply</button>,
      },
      // New column for "Actions"
      {
        Header: 'Actions',
        accessor: 'applyUrlAction', // Change the accessor id to make it unique
        Cell: ({ row }) => (
          <button onClick={() => handleApplyClick(row.original.applyUrl)}>Auto-Apply</button>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: jobs }, useSortBy);

  return (
    <>
      <table {...getTableProps()} className="table" style={{ fontSize: '12px' }}> {/* Adjust font size as needed */}
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : ''
                  }
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {showModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={() => setShowModal(false)}>&times;</span>
              <h2>Apply for Job</h2>
              <label>
                As who:
                <select value={asWho} onChange={(e) => setAsWho(e.target.value)}>
                  <option value="Freelancer">Freelancer</option>
                  <option value="Agency">Agency</option>
                </select>
              </label>
              <br/>
              <br/>
              <label>
                Attachment:
                <select value={attachment} onChange={(e) => setAttachment(e.target.value)}>
                  <option value="Node.js">Node.js</option>
                  <option value="Architect">Architect</option>
                  <option value="C++">C++</option>
                </select>
              </label>
              <br/>
              <br/>
              <label>
                Cover letter:
                <select value={coverLetter} onChange={(e) => setCoverLetter(e.target.value)}>
                  <option value="Me as Node.js">Me as Node.js</option>
                  <option value="Me as Architect">Me as Architect</option>
                  <option value="Me as C++">Me as C++</option>
                </select>
              </label>
              <br/>
              <br/>
              <button onClick={handleApply}>Apply</button>
              <button onClick={() => setShowModal(false)}>Cancel</button>
            </div>
          </div>
        )}

    </>
  );
};

export default JobTableUpwork;
