import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import './home.css';
import { FaCopy } from 'react-icons/fa';
import { lookupOrg, lookupOrgJobs, lookupPoc, lookupPocDetails } from '../../services/lookup';
import { downloadJsonAsXl } from '../../utils/downloadXl';
import logger from '../../utils/logger';

const ROLES = {
    CTO: 'CTO,Chief Technology Officer',
    CIO: 'CIO,Chief Information Office',
    CEO: 'CEO,Chief Executive Officer',
    Founder: 'Founder,Co-founder,Cofounder,Owner,Founder & CEO',
    Manager: 'Manager,Director,Manager & CEO',
    BizDev: 'Business Development,Business Development Manager',
};

const LOOKUP_JOBS_ENABLED = process.env.REACT_APP_ENABLE_LOOKUP_JOBS;

const Home = () => {
    const [companyName, setCompanyName] = useState('');
    const [personaTypeInLookupPoc, setPersonaTypeInLookupPoc] = useState(ROLES['CTO']);
    const [personaTypeInLookupCompanies, setPersonaTypeInLookupCompanies] = useState(ROLES['CTO']);
    const [industry, setIndustry] = useState('');
    const [location, setLocation] = useState('United States');
    const [headcount, setHeadcount] = useState('1,50');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [source, setSource] = useState('LeadIQ');

    const handleRequestError = (error) => {
        logger.error('Error in processing input:', error);
        alert(`Error in processing input: ${error.message}`);
    };

    const handleLookupCompanies = async () => {
        setLoading(true);
        try {
            logger.log(`Calling lookupOrg with params: ${JSON.stringify({ industry, location, headcount, source })}`);
            const { companySize, companies: orgs } = await lookupOrg(undefined, industry, location, headcount, source);
            logger.log(`lookupOrg returned: ${JSON.stringify({companySize, orgs })}`);

            const leads = (await Promise.all(
                orgs.map(async (org) => {
                    logger.log(`Processing organization: ${org}`);
                    const peopleLeads = await Promise.all(
                        org.people.map(async (person) => {
                            const lead = await lookupPoc(person.id, [], personaTypeInLookupCompanies);
                            return lead ? { ...lead, organization: org } : null;
                        })
                    );
                    return peopleLeads.filter(Boolean);
                })
            )).flat();

            const checkedLeads = (
                await Promise.all(
                    leads.map(async (lead) => {
                        const pocDetails = await lookupPocDetails(lead);
                        if (pocDetails.email) {
                            return {
                                companyName: lead.organization.company.name,
                                name: lead.name.fullName,
                                email: pocDetails.email,
                                role: pocDetails.role,
                                companySize,
                                industry: lead.organization.company.industry,
                                location,
                            };
                        }
                        return null;
                    })
                )
            ).filter(Boolean);

            let text = 'No leads found to download.';
            if (checkedLeads.length > 0) {
                await downloadJsonAsXl(checkedLeads);
                text = `Excel has been downloaded with ${checkedLeads.length} leads`;
            }
            alert(text);
        } catch (error) {
            handleRequestError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleLookupPoc = async () => {
        setLoading(true);
        setResult(null);

        try {
            const orgIds = await lookupOrg(companyName, undefined, undefined, undefined, source.toLowerCase());
            if (!orgIds || orgIds.length === 0) {
                throw new Error('No organization found');
            }

            const orgId = orgIds.find((id) => id);
            const jobs = orgId && LOOKUP_JOBS_ENABLED ? await lookupOrgJobs(orgId) : null;

            const poc = await lookupPoc(orgIds, personaTypeInLookupPoc);
            if (!poc || poc.length === 0) {
                throw new Error(`No ${personaTypeInLookupPoc} found at ${companyName}`);
            }

            const pocDetail = await lookupPocDetails(poc.person_id || poc.id);

            setResult({
                name: pocDetail.name || poc.name,
                email: pocDetail.email || poc.email,
                role: pocDetail.title || poc.title,
                company: companyName,
                companyId: pocDetail.organization_id || poc.organization_id,
                jobs,
            });
        } catch (error) {
            handleRequestError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <div className="two-column-layout">
            {/* Part 1: Company Name, Persona Type, and Find Button */}
            <div className="column">
                <h3 className="column-heading">Find a single lead</h3>
                <div className="input-group">
                    <label htmlFor="companyName">Company Name:</label>
                    <input
                        type="text"
                        id="companyName"
                        value={companyName} // Controlled input with initialized value
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="location">Location:</label>
                    <select id="location" value="World" disabled>
                        <option value="United States">United States</option>
                        <option value="World">World</option>
                        <option value="Canada">Canada</option>
                        <option value="United Arab Emirates">Emirates</option>
                    </select>
                </div>
                <div className="input-group">
                    <label htmlFor="headcount">Headcount:</label>
                    <select id="headcount" disabled>
                        <option value="Any">Any</option>
                    </select>
                </div>
                <div className="input-group">
                    <label htmlFor="personaTypeInFindPoc">Persona Type:</label>
                    <select
                        id="personaTypeInFindPoc"
                        value={personaTypeInLookupPoc} // Controlled input
                        onChange={(e) => setPersonaTypeInLookupPoc(e.target.value)}
                    >
                        {Object.keys(ROLES).map((role) => (
                            <option key={role} value={ROLES[role]}>
                                {role}
                            </option>
                        ))}
                    </select>
                </div>
                <Button onClick={handleLookupPoc}>Submit</Button>

                {result && (
                    <div className="result-container">
                        {['name', 'email', 'role', 'company', 'companyId'].map((field) => (
                            <p key={field}>
                                {field.charAt(0).toUpperCase() + field.slice(1)}: {result[field]}{' '}
                                <FaCopy onClick={() => handleCopyToClipboard(result[field])} style={{ cursor: 'pointer' }} />
                            </p>
                        ))}
                        {result.jobs && (
                            <div className="job-links">
                                <p>Open {result.jobs.length} jobs</p>
                                {result.jobs.map((job, index) => (
                                    <div key={index} className="job-link-container">
                                        {job.url && (
                                            <a href={job.url} target="_blank" rel="noopener noreferrer">
                                                {job.title}
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="divider"></div>

            {/* Part 2: Industry, Location, Persona Type, and Find Button */}
            <div className="column">
                <h3 className="column-heading">Find multiple leads</h3>
                <div className="input-group">
                    <label htmlFor="source">Source:</label>
                    <select id="source" value={source} onChange={(e) => setSource(e.target.value)}>
                        <option value="leadiq">LeadIQ</option>
                    </select>
                </div>
                <div className="input-group">
                    <label htmlFor="industry">Industry:</label>
                    <input
                        type="text"
                        id="industry"
                        value={industry || ''} // Controlled input with default value
                        onChange={(e) => setIndustry(e.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="location">Location:</label>
                    <input
                        list="location-options"
                        id="location"
                        value={location || ''} // Controlled input with default value
                        onChange={(e) => setLocation(e.target.value)}
                    />
                    <datalist id="location-options">
                        <option value="United States" />
                        <option value="Canada" />
                        <option value="United Arab Emirates" />
                        <option value="Hong Kong" />
                        <option value="Singapore" />
                        <option value="Germany" />
                        <option value="Switzerland" />
                        <option value="Latvia" />
                    </datalist>
                </div>
                <div className="input-group">
                    <label htmlFor="headcount">Headcount:</label>
                    <select id="headcount" value={headcount} onChange={(e) => setHeadcount(e.target.value)}>
                        <option value="1,50">1-50</option>
                        <option value="51,100">51-100</option>
                        <option value="101,500">101-500</option>
                        <option value="501,1000">501-1000</option>
                        <option value="1001,5000">1001-5000</option>
                        <option value="5001,10000">5001-10000</option>
                    </select>
                </div>
                <div className="input-group">
                    <label htmlFor="personaTypeInFindCompanies">Persona Type:</label>
                    <select
                        id="personaTypeInFindCompanies"
                        value={personaTypeInLookupCompanies} // Controlled input
                        onChange={(e) => setPersonaTypeInLookupCompanies(e.target.value)}
                    >
                        {Object.keys(ROLES).map((role) => (
                            <option key={role} value={ROLES[role]}>
                                {role}
                            </option>
                        ))}
                    </select>
                </div>
                <Button onClick={handleLookupCompanies}>Submit</Button>
            </div>

            {loading && (
                <div className="loading-spinner-overlay fullscreen">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </div>
    );
};

export default Home;
