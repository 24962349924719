import React, { useEffect, useState, useCallback } from 'react';
import './Header.css';
import { whoAmI } from '../../services/whoAmI';
import { getUserStats } from '../../services/userStats';
import { emailInSpamDb } from "../../services/emailInSpamDb";
import { lookupStatus } from "../../services/lookup";
import { setGlobalEmail } from "../../utils/globals";

const outreachesPerDayLimit = process.env.REACT_APP_OUTREACHES_PER_DAY_LIMIT || 0;

const useInitializeData = (user, setUser, setUserStats, setUserEmailInSpamDb, setLookupApiStatus, setIsLoading) => {
  const fetchUserEmailInSpamDb = useCallback(async () => {
    if (user?.email) {
      const isEmailInSpamDb = await emailInSpamDb(user.email);
      setUserEmailInSpamDb(isEmailInSpamDb);
    }
  }, [user?.email, setUserEmailInSpamDb]);

  const fetchUserDetails = useCallback(async () => {
    const statsData = await getUserStats();
    if (statsData) {
      setUserStats(statsData);
    }
  }, [setUserStats]);

  const fetchUser = useCallback(async () => {
    const userData = await whoAmI();
    setGlobalEmail(userData.email);
    setUser(userData);
  }, [setUser]);

  const fetchApiStatus = useCallback(async () => {
    const apiStatusData = await lookupStatus();
    if (apiStatusData) {
      setLookupApiStatus(apiStatusData);
    }
  }, [setLookupApiStatus]);

  useEffect(() => {
    const initializeData = async () => {
      await fetchUser();  // Fetch user data first
      await fetchUserDetails();  // Then fetch user stats
      await fetchApiStatus();  // Fetch API status after
      await fetchUserEmailInSpamDb();  // Finally, check spam database
      setIsLoading(false);  // Set loading to false once everything is fetched
    };

    initializeData();
  }, []);
};

const Header = () => {
  const [user, setUser] = useState(null);
  const [userStats, setUserStats] = useState(null);
  const [userEmailInSpamDb, setUserEmailInSpamDb] = useState(false);
  const [lookupApiStatus, setLookupApiStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useInitializeData(user, setUser, setUserStats, setUserEmailInSpamDb, setLookupApiStatus, setIsLoading);

  useEffect(() => {
    let fetchUserDetailsInterval, fetchApiStatusInterval;

    if (user) {
      // Setup intervals after user is fetched
      fetchUserDetailsInterval = setInterval(() => {
        getUserStats().then(setUserStats); // Fetch user stats and update state
      }, 10 * 60 * 1000); // Fetch every 10 minutes

      fetchApiStatusInterval = setInterval(() => {
        lookupStatus().then(setLookupApiStatus); // Fetch API status and update state
      }, 5 * 60 * 1000); // Fetch every 5 minutes
    }

    // Clear intervals on unmount or when user changes
    return () => {
      clearInterval(fetchUserDetailsInterval);
      clearInterval(fetchApiStatusInterval);
    };
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>; // Loading state while data is being fetched
  }

  if (!user) {
    return null;
  }

  const formatLookupsAvailable = (left, total, period) => `${left}/${total} (${period})`;

  return (
      <div className='header-container'>
        <div className='banner-container'>
          {`Hello, ${user.name}`}
          <br />
          {`Email: ${user.email}`}
          <br />
          {userEmailInSpamDb && <span style={{ color: 'red' }}>Found in SPAM DB</span>}
        </div>

        <div className='total-emails-normal'>
          <span className='title'>📧 Year-to-date</span>
          <span className='value'>{userStats?.count?.ytd} (o/r {userStats?.open_rate?.ytd}%)</span>
        </div>
        <div className='total-emails-normal'>
          <span className='title'>📧 Month-to-date</span>
          <span className='value'>{userStats?.count?.mtd} (o/r {userStats?.open_rate?.mtd}%)</span>
        </div>
        <div className='total-emails-highlighted'>
          <span className='title'>📧 Week-to-date</span>
          <span className='value'>{userStats?.count?.wtd} (o/r {userStats?.open_rate?.wtd}%)</span>
        </div>
        <div className='total-emails-highlighted'>
          <span className='title'>📧 Today</span>
          <span className='value'>{userStats?.count?.td} of {outreachesPerDayLimit}</span>
        </div>

        {/* Daily Limits Section */}
        {/* for apollo* /}
        {/*{lookupApiStatus?.apiLimits?.requestLimits && (
            <div className='api-limits-container'>
              <h4>Lookups Usage</h4>
              <div>{formatLookupsAvailable(lookupApiStatus.apiLimits.requestLimits.minuteRequestsLeft, lookupApiStatus.apiLimits.requestLimits.rateLimitMinute, "minute")}</div>
              <div>{formatLookupsAvailable(lookupApiStatus.apiLimits.requestLimits.hourlyRequestsLeft, lookupApiStatus.apiLimits.requestLimits.rateLimitHourly, "hour")}</div>
            </div>
        )}*/}
        {(lookupApiStatus?.units && lookupApiStatus?.cap && (
            <div className='api-limits-container'>
              <h4>Lookups Usage</h4>
              <div>{formatLookupsAvailable(lookupApiStatus.units, lookupApiStatus.cap, "total")}</div>
            </div>
        ))}
      </div>
  );
};

export default Header;
