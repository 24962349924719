import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import JobTableUpwork from './JobTableUpwork';
import JobTableTwitter from './JobTableTwitter'; // Import the new component
import './home.css';
import {
    lookupJobsIndeed, lookupJobsUpwork, lookupJobsTwitter,
} from "../../services/lookup";
import { downloadJsonAsXl } from "../../utils/downloadXl";
import { checkFeatures } from "../../services/checkFeatures";
import logger from '../../utils/logger';

const Home = () => {
    const [keywords, setKeywords] = useState('');
    const [city, setCity] = useState('');
    const [source, setSource] = useState('upwork');
    const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [count, setCount] = useState(1);
    const [features, setFeatures] = useState([]);

    const fetchJobs = async () => {
        setLoading(true);
        let openJobs = [];

        try {
            switch (source.toLowerCase()) { // To handle lowercase source names
                case 'indeed':
                    openJobs = await lookupJobsIndeed(keywords, city);
                    break;
                case 'upwork':
                    openJobs = await lookupJobsUpwork(keywords, city, count);
                    break;
                case 'twitter':
                    openJobs = await lookupJobsTwitter(keywords, city, count);
                    break;
                default:
                    logger.error('Invalid source:', source);
            }
        } catch (error) {
            logger.error('Error fetching jobs:', error.message);
            alert(error.message);
        } finally {
            setLoading(false);
        }

        return openJobs;
    };

    const handleFindJobs = async () => {
        const openJobs = await fetchJobs();
        switch (source.toLowerCase()) {
            case 'indeed':
                await downloadJsonAsXl(openJobs);
                break;
            case 'upwork':
            case 'twitter':
                setJobs(openJobs);
                break;
            default:
                logger.error('Invalid source:', source);
                break;
        }
    };

    useEffect(() => {
        const fetchAllowedFeatures = async () => {
            try {
                const response = await checkFeatures();
                const availableJobsFeatures = response.jobs.split(',');
                setFeatures(availableJobsFeatures);

                // Set the initial source if the current source is not in available features
                /*
                 to ensure that source is always set to a valid option from the features array. Here’s why this is necessary:

                    Initial State Mismatch: When the component first loads, source is initialized to 'upwork', but features may not contain 'upwork' (depending on the values returned by checkFeatures). If source doesn’t match any of the features options, it could result in an incorrect selection or empty dropdown.

                    Ensuring Valid Selection: By setting source to the first item in features when the current source is not in features, we guarantee that source will always reflect a valid dropdown option. This prevents cases where the user might see an incorrect or empty option in the dropdown.

                    Dynamic Feature Loading: Since features are loaded asynchronously, it’s possible that features isn’t fully populated at the initial render. This check ensures that once features loads, source automatically aligns with an available option.
                */
                if (!availableJobsFeatures.includes(source)) {
                    setSource(availableJobsFeatures[0]);
                }
            } catch (error) {
                logger.error('Error fetching allowed Jobs features:', error);
            }
        };

        fetchAllowedFeatures();
    }, [source]);

    return (
        <div className="home-container">
            <div className="two-column-layout">
                <div className="column">
                    <h3 className="column-heading">Find jobs</h3>
                    <div className="input-group">
                        <label htmlFor="keywords">Keywords</label>
                        <input
                            type="text"
                            id="keywords"
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="city">Location</label>
                        <input
                            type="text"
                            id="city"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="source">Source</label>
                        <select
                            id="source"
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                        >
                            {features.map((feature) => (
                                <option key={feature} value={feature}>{feature}</option>
                            ))}
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="count">Count</label>
                        <select
                            id="count"
                            value={count}
                            onChange={(e) => setCount(parseInt(e.target.value))}
                        >
                            {[1, 5, 10, 20, 50, 100, 1000].map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                    <Button onClick={handleFindJobs} style={{ marginLeft: '0px' }}>
                        Submit
                    </Button>
                </div>

                <div className="divider"></div>

                {loading && (
                    <div className="loading-spinner-overlay fullscreen">
                        <div className="loading-spinner"></div>
                    </div>
                )}
            </div>

            {/* Render JobTable based on the source */}
            {jobs && jobs.length > 0 && (
                <div className="jobs-container">
                    <h1>Jobs Available</h1>
                    {source.toLowerCase() === 'twitter' ? (
                        <JobTableTwitter jobs={jobs} />
                    ) : (
                        <JobTableUpwork jobs={jobs} />
                    )}
                </div>
            )}
        </div>
    );
};

export default Home;
