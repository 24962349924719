import {getApiUrl} from "../utils/getApiUrl";
import {fetchWithUserId} from "../utils/apiCalls";
import logger from '../utils/logger';

export const checkPassword = async (password) => {
    try {
        let result = await fetchWithUserId(`${getApiUrl()}/check_password?password=${password}`);
            
        if (!result.ok) {
            // Handle non-successful HTTP responses
            throw new Error(`HTTP error! Status: ${result.status}`);
        }

        result = await result.json()
        return result.status === 'OK';
    } catch (error) {
        // Handle network errors, parsing errors, or any other unexpected issues
        logger.error("An error occurred during the API call:", error);
        return false;
    }
};
