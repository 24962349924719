import React from 'react';

const JobTableTwitter = ({ jobs }) => {
    return (
        <table className="job-table">
            <thead>
            <tr>
                <th>Author</th>
                <th>Tweet</th>
                {/*<th>Source</th>*/}
                <th>Created At</th>
                <th>URL</th>
            </tr>
            </thead>
            <tbody>
            {jobs.map((job) => (
                <tr key={job.id}>
                    <td>{job.author.userName}</td>
                    <td>{job.text}</td>
                    {/*<td>{job.source}</td>*/}
                    <td>{new Date(job.createdAt).toLocaleString()}</td>
                    <td><a href={job.url} target="_blank" rel="noopener noreferrer">View Tweet</a></td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default JobTableTwitter;
