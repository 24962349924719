import { getApiUrl } from "../utils/getApiUrl";
import { fetchWithUserId } from "../utils/apiCalls";
import logger from "../utils/logger";

export const checkFeatures = async () => {
    try {
        // Make the API call to check available features
        let result = await fetchWithUserId(`${getApiUrl()}/check_features`);

        if (!result.ok) {
            // Handle non-successful HTTP responses
            throw new Error(`HTTP error! Status: ${result.status}`);
        }

        // Parse the JSON response
        return await result.json();
    } catch (error) {
        // Handle network errors, parsing errors, or any other unexpected issues
        logger.log('error', `An error occurred during the API call check_features: ${error}`);
        return [];
    }
};
