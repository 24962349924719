import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';
import IconMail from '../../assets/mail-icon.png';
import IconMenu from '../../assets/menu-icon.png';
import IconMagnify from '../../assets/magnifier-icon.png';
import IconPieChart from '../../assets/pie-chart-icon.png';
import IconJobs from '../../assets/jobs-icon.png';
import {checkFeatures} from '../../services/checkFeatures';
import logger from "../../utils/logger";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [allowedElements, setAllowedElements] = useState([]);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const paths = location.pathname.split('/');
  const selectedPath = paths[paths.length - 1];

  const allSidebarElements = [
    { path: 'lookup', icon: IconMagnify },
    { path: 'jobs', icon: IconJobs },
    { path: 'template', icon: IconMail },
    { path: 'leads', icon: IconPieChart },
  ];

  // Function to fetch available features from the API
  useEffect(() => {
    const fetchAllowedFeatures = async () => {
      try {
        const response = await checkFeatures();
        const availableFeatures = response.features; // e.g. ['lookup', 'jobs']

        // Filter out only the elements that are allowed based on the API response
        const filteredElements = allSidebarElements.filter(element =>
            availableFeatures.includes(element.path)
        );

        setAllowedElements(filteredElements);
      } catch (error) {
        logger.error('Error fetching allowed features:', error);
      }
    };

    fetchAllowedFeatures();
  }, []);

  return (
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <button className="toggle-button" onClick={toggleSidebar}>
          <img src={IconMenu} alt="toggle" />
        </button>
        <div className="sidebar-content">
          {allowedElements.map((element) => (
              <Link
                  key={element.path}
                  className={`sidebar-element ${selectedPath === element.path ? 'active-page' : ''}`}
                  to={`/${element.path}`}
              >
                <img src={element.icon} alt={element.path} />
              </Link>
          ))}
        </div>
      </div>
  );
};

export default Sidebar;
