import { getApiUrl, getWsUrl } from "../utils/getApiUrl";
import pMemoize from "p-memoize";
import { axiosGetWithUserId, axiosPostWithUserId } from "../utils/apiCalls";
import logger from '../utils/logger';

// Define maxAge constant (14 days in milliseconds)
const MAX_AGE_14_DAYS = 14 * (24 * 60 * 60 * 1000);

// WebSocket connection (assumed globally or within this file)
const ws = new WebSocket(`${getWsUrl()}`);

// Memoized function to get point of contact (POC) details
export const lookupPocDetails = pMemoize(async (id) => {
    try {
        const { data, status } = await axiosGetWithUserId(`${getApiUrl()}/lookup_poc_details`, { params: { id } });
        if (status !== 200) return [];
        return data;
    } catch (error) {
        logger.error("API call error in lookupPocDetails:", error);
        return [];
    }
}, { cacheKey: ([id]) => id, maxAge: MAX_AGE_14_DAYS });

// Memoized function to look up POC by company IDs and personas type
export const lookupPoc = pMemoize(async (personId, companyIds, personasType) => {
    try {
        const { data, status } = await axiosGetWithUserId(`${getApiUrl()}/lookup_poc`, {
            params: { personId, companyIds: companyIds.join(','), personasType }
        });
        return status === 200 && data.length ? data[0] : null;
    } catch (error) {
        logger.error("Error in lookupPoc:", error);
    }
}, { cacheKey: ([companyIds, personasType]) => `${companyIds}:${personasType}`, maxAge: MAX_AGE_14_DAYS });

// Memoized function to look up organizations
export const lookupOrg = pMemoize(async (companyName, keywords, locations, orgNumEmployeesRanges, source) => {
    try {
        const { data, status } = await axiosGetWithUserId(`${getApiUrl()}/lookup_org`, {
            params: { companyName, keywords, locations, orgNumEmployeesRanges, source }
        });
        if (status !== 200) throw new Error("Failed to retrieve organization data");
        return data;
    } catch (error) {
        logger.error("Error in lookupOrg:", error);
    }
}, { cacheKey: (args) => args.join(':'), maxAge: MAX_AGE_14_DAYS });

// Memoized function to look up jobs by organization
export const lookupOrgJobs = pMemoize(async (companyId) => {
    try {
        const { data, status } = await axiosGetWithUserId(`${getApiUrl()}/lookup_org/jobs`, { params: { companyId } });
        if (status !== 200) throw new Error("Failed to retrieve jobs for organization");
        return data;
    } catch (error) {
        logger.error("Error in lookupOrgJobs:", error);
    }
}, { cacheKey: ([companyId]) => companyId, maxAge: MAX_AGE_14_DAYS });

// Function to check lookup status
export const lookupStatus = async () => {
    try {
        const { data, status } = await axiosGetWithUserId(`${getApiUrl()}/lookup_status`);
        if (status !== 200) throw new Error("Failed to retrieve lookup status");
        return data;
    } catch (error) {
        logger.error("Error in lookupStatus:", error);
    }
};

// Generic memoized function to look up jobs via WebSocket
export const lookupJobs = pMemoize(async (action, keywords, location = 'United States', count) => {
    return new Promise((resolve, reject) => {
        const message = JSON.stringify({ action, keywords, location, count });
        ws.send(message);

        ws.onmessage = ({ data }) => {
            const response = JSON.parse(data);
            if (response.action === action) {
                if (response.status === 'success') {
                    resolve(response.data);
                } else {
                    reject(new Error(`Error fetching job data from ${action}`));
                }
            }
        };

        ws.onerror = (error) => {
            logger.error(`WebSocket error in ${action}:`, error);
            reject(error);
        };

        ws.onclose = () => {
            logger.info(`WebSocket connection closed for ${action}`);
        };
    });
}, { cacheKey: ([action, keywords, location, count]) => `${action}:${keywords}:${location}:${count}`, maxAge: 24 * 60 * 60 * 1000 });

// Wrapper functions for Upwork and Twitter job lookups
export const lookupJobsUpwork = (keywords, location, count) => lookupJobs('lookupJobsUpwork', keywords, location, count);
export const lookupJobsTwitter = (keywords, location, count) => lookupJobs('lookupJobsTwitter', keywords, location, count);

// Function to apply to jobs on Upwork
export const applyJobsUpwork = async ({ useAgency, useFreelancer, attachmentUrl, coverLetter, jobUrl }) => {
    try {
        const { data, status } = await axiosPostWithUserId(`${getApiUrl()}/lookup_org/jobs/search/upwork`, null, {
            params: { useAgency, useFreelancer, attachmentUrl, coverLetter, jobUrl }
        });
        if (status !== 200) throw new Error("Failed to apply for Upwork jobs");
        return data;
    } catch (error) {
        logger.error("Error in applyJobsUpwork:", error);
        throw error; // Rethrow to handle in the calling code
    }
};

export const lookupJobsIndeed = pMemoize(
    async (keywords, location) => {

        if (!location) {
            location = 'United States'
        }
        try {
            let response = await axiosGetWithUserId(`${getApiUrl()}/lookup_org/jobs/search/indeed`, {
                params: {
                    location,
                    keywords
                },
            });

            if (response.status !== 200) {
                throw new Error(response.statusText);
            }

            const orgData = response.data;
            return orgData
        } catch (error) {
            logger.error(`Error: ${error}`);
        }
    },
    {
        cacheKey: (args) => `${args[0]}`,
        maxAge: 14 * (24 * 60 * 60 * 1000), // 14 days
    }
)
