const usHolidays = [
    "2024-11-11", // Veterans Day
    "2024-11-28", // Thanksgiving Day
    "2024-12-24", // Christmas Eve
    "2024-12-25", // Christmas Day
    "2024-12-31", // New Year's Eve
    "2025-01-01", // New Year's Day
    "2025-01-20", // Martin Luther King Jr. Day
    "2025-02-17", // Presidents' Day
    "2025-04-01", // April Fools' Day
    "2025-05-26", // Memorial Day
    "2025-07-04", // Independence Day
    "2025-09-01", // Labor Day
    "2025-10-13", // Columbus Day
    "2025-11-11", // Veterans Day
    "2025-11-27", // Thanksgiving Day
    "2025-12-24", // Christmas Eve
    "2025-12-25", // Christmas Day
    "2025-12-31", // New Year's Eve
]

// Function to check if a date is a US holiday (example)
export const isUSHoliday = (date) => {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    return usHolidays.includes(formattedDate);
};